var Roles = {
    list: {
        init: function()
        {
            dataTable.tables['DT-Roles-List'] = {
                tbl : $('#DT-Roles-List').dataTable({
                    columnDefs: [
                        {
                            width: '50px',
                            targets: -1,
                        }
                    ],
                    language : {
                        search : 'Filter Roles',
                    }
                }),
            };

            Roles.list.refreshEvents();
        },

        refreshEvents: function()
        {
            $('.delete-role-btn')
                .off('click')
                .on('click', function () {
                    var role_id = Number($(this).parentsUntil('tr').parent().attr('data-id'));

                    Roles.list.showDeleteConfirm(role_id);
                })
        },

        showDeleteConfirm: function (id)
        {
            popup.generatePopup({
                title: 'Confirm Role Deletion',
                method: 'ajax',
                ajaxUrl: '/AJAX-roles/show-confirm-delete-popup',
                maxWidth: 450,
                maxHeight: 200,
                success: function()
                {
                    $('#confirm-delete-role-btn')
                        .on('click', function (evt) {
                            Roles.list.deleteRole(role_id);
                        });
                }
            });
        },

        deleteRole: function (id)
        {
            var send_data = {
                id: id,
            };

            $.ajax({
                url: '/admin/AJAX-roles/delete',
                type: 'post',
                data: send_data,
                dataType: 'json',
                success: function (returnedData) {
                    if (returnedData.success) {
                        main.flashData.show('Role deleted successfully', 'positive', 5);

                        dataTable
                            .tables["DT-Roles-List"]
                            .tbl
                            .api()
                            .row('[data-id="' + id + '"]')
                            .remove()
                            .draw();
                    }
                },
                error: function (err) {
                    console.error(err);
                }
            });
        },

        resetActionBtns: function (id)
        {
            $('tr[data-id="' + id + '"] div.btn-bank-host')
                .empty()
                .append(
                    $('<a></a>')
                        .attr('href', '/admin/roles/assign-users/' + id)
                        .addClass('assign-role-btn')
                        .addClass('btn')
                        .addClass('btn-primary')
                        .addClass('btn-bank')
                        .text('Assign Users'),
                    $('<a></a>')
                        .attr('href', '/admin/roles/edit/' + id)
                        .addClass('edit-role-btn')
                        .addClass('btn')
                        .addClass('btn-light')
                        .addClass('btn-bank')
                        .append(
                            $('<i></i>')
                                .addClass('fa')
                                .addClass('fa-pencil-alt')
                        ),
                    $('<a></a>')
                        .addClass('delete-role-btn')
                        .addClass('btn')
                        .addClass('btn-danger')
                        .addClass('btn-bank')
                        .append(
                            $('<i></i>')
                                .addClass('fa')
                                .addClass('fa-trash')
                        )
                );

            Roles.list.refreshEvents();
        }
    },

    delete: {
        summary: {
            init: function()
            {
                $('#dt-role').dataTable();
                $('#dt-assigned-users').dataTable();

                $('#delete-role-form').submit(function (evt) {
                    evt.preventDefault();

                    var send_data = {
                        role_id: $('#role-id').val(),
                        deletions: [],
                    };

                    $('tr.user-role-delete-action').each(function (index, elem) {
                        var user_id = $(elem).attr('data-user-id');

                        send_data.deletions.push({
                            user_id: user_id,
                            delete_action: $(elem).find('input[name="delete-action-' + user_id + '"]:checked').val(),
                        });
                    });

                    $.ajax({
                        url: '/admin/AJAX-roles/delete',
                        data: send_data,
                        type: 'post',
                        dataType: 'text',
                        success: function (returned_data) {
                            console.log(returned_data);
                            returned_data = JSON.parse(returned_data);
                            console.log(returned_data);
                            if (returned_data.success) {
                                console.log("success");
                                main.flashData.show(
                                    "Role successfully deleted...",
                                    "positive",
                                    5
                                );

                                setTimeout(function() {
                                    window.location.href = "/admin/roles";
                                }, 5000);
                            }
                        }
                    });
                });
            }
        }
    }
};
